<template>
  <div class="container mb-2 text-center-mobile-only p-5">
    <h2 class="title is-4-5 mb-6 has-text-centered has-text-weight-medium">
      НАПРАВЛЕНИЯ ТОРГОВ
    </h2>
    <div
      class="
        columns
        is-multiline
        is-variable
        is-1-mobile
        is-2-tablet
        is-4-desktop
        is-5-widescreen
        is-6-fullhd
        torg-items
        is-desktop
      "
    >
      <div
        class="column is-4-desktop mb-4 torg-item"
        v-for="item in torgItems"
        :key="item.title"
      >
        <div
          class="notification height-1-hd shadow br-3 p-5 has-hoverable-style is-flex"
        >
          <div class="columns">
            <div
              class="
                column 
                trading-icon-height-width 
                is-3 
                is-flex is-justify-content-center is-align-items-center
              "
            >
              <div class="torg-icon">
                <img class="torg-img" :src="item.img"/>
                <img class="torg-img-white" :src="item.img_hover"/>
                </div>
            </div>
            <div class="column is-9 pt-5-d pl-0">
              <h3 class="title is-4 has-text-weight-medium">
                {{ item.title }}
              </h3>
              <p class="text-promo">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /columns -->
  </div>
  <!-- /container -->
</template>

<script>
export default {
  name: "TradingDirection",
  data: () => {
    return {
      torgItems: [
        {
          id: 1,
          title: "Товары и оборудование",
          text: "К участию приглашаются производители и поставщики кровельных, фасадных, ограждающих и строительных материалов, материалов и оборудования для производства металлоконструкций.",
          type: 1,
          img: require('@/assets/svg/tovari.svg'),
          img_hover: require('@/assets/svg/tovari-white.svg')
        },
        {
          id: 2,
          title: "Металлоконструкции",
          text: "К участию приглашаются заводы по производству строительных металлоконструкций и горячему цинкованию.",
          type: 1,
          img: require('@/assets/svg/metal.svg'),
          img_hover: require('@/assets/svg/metal-white.svg')

        },
        {
          id: 3,
          title: "Строительно-монтажные работы",
          text: "К участию приглашаются строительные организации, занимающиеся производством работ",
          type: 1,
          img: require('@/assets/svg/stroi.svg'),
          img_hover: require('@/assets/svg/stroi-white.svg')
        },
        {
          id: 4,
          title: "Услуги",
          text: "К участию приглашаются организации, оказывающие информационные, консалтинговые, услуги производственного характера и др.",
          type: 1,
          img: require('@/assets/svg/uslugi.svg'),
          img_hover: require('@/assets/svg/uslugi-white.svg')
        },
        {
          id: 5,
          title: "Проектирование",
          text: "К участию приглашаются организации, занимающиеся созданием проектной документации стадии КР, АР, АС, КЖ, КМ, КМД и др.",
          type: 1,
          img: require('@/assets/svg/proekt.svg'),
          img_hover: require('@/assets/svg/proekt-white.svg')
        },
        {
          id: 6,
          title: "Прочее",
          text: "К участию приглашаются организации, выполняющие сертификацию, аккредитацию и др.",
          type: 1,
          img: require('@/assets/svg/other.svg'),
          img_hover: require('@/assets/svg/other-white.svg')  

        },
      ],
    };
  },
};
</script>
