<template>
  <transition name="welcome-popup-transition">
    <div
        class="welcome-popup"
        v-if="isShown"
    >
      <div class="welcome-popup__dg" @click.stop="close"/>
      <div class="welcome-popup__content">
        <div class="welcome-popup__close" @click="close"/>
        <p class="welcome-popup__content-step">
          Шаг {{activePage+1}}/3
        </p>
        <div class="welcome-popup__card">
          <h2 class="welcome-popup__card-title">{{ slider.title }}</h2>
          <p class="welcome-popup__card-description">
            {{slider.description}}
          </p>
          <div class="welcome-popup__card-image">
            <img :src="slider.image" :alt="slider.title">
          </div>
        </div>
        <div class="welcome-popup__content-actions">
          <button class="button is-normal border-button" @click="previous">Назад</button>
          <button v-if="activePage!==2" class="button is-normal main-button purple" @click="next">Далее</button>
          <button v-else class="button is-normal main-button purple" @click="close">Начать</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import authService from '@/services/auth.service';
const EVENTS = {updateIsShown: 'update:isShown'}

export default {
  name: 'welcome-popup',

  props: {
    isShown: {type: Boolean, default: false},
    popupName: {type: String, default: ''},
  },
  data: () => ({
    sliderList: [
      {
        "title": "Добро пожаловать!",
        "description": `Добро пожаловать, ${authService.getUsername()}! Теперь у Вас есть возможность просмотра списка конкурсов на закупку и участия в конкурсах на закупку, проводимых компанией ЕВРАЗ Стил Билдинг.`,
        "image": require('@/assets/img/welcome-popup.png'),
      },
      {
        "title": "Заявка для участия в тендерах",
        "description": "На странице тендера имеются вкладки с полной информацией о " +
            "процедуре и предмете закупки. Для участия в конкурсе нужно нажать кнопку «Подать заявку».",
        "image": require('@/assets/img/welcome-popup2.png'),
      },
      {
        "title": "Участие в тендерах",
        "description": "Далее Вам следует заполнить форму заявки и ожидать обратную связь о результатах выбора поставщика в виде электронного письма на почтовый адрес, указанный при регистрации Вашего аккаунта. Желаем Вам легкой работы на портале закупок ЕВРАЗ Стил Билдинг!",
        "image": require('@/assets/img/welcome-popup3.png'),
      },
    ],
    activePage: 0,
  }),
  computed: {
    slider () {
      const slide = this.sliderList.slice(this.activePage, this.activePage+1)
      return slide[0]
    },
  },
  methods: {
    close() {
      this.$router.push({name: 'Home'})
    },
    next () {
      if (this.activePage < 2) {
        this.activePage++
      } else {
        this.activePage = 0
      }
    },
    previous () {
      if (this.activePage !== 0) {
        this.activePage--
      } else {
        this.activePage = 2
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
}
.welcome-popup__dg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(56, 0, 27, 0.2);
}

.welcome-popup__close {
  position: absolute;
  background-image: url("../../assets/svg/close-icon.svg");
  width: 24px;
  height: 24px;
  background-size: cover;
  top: 34px;
  right: 34px;
}

.welcome-popup__content {
  position: relative;
  z-index: 104;
  width: 50%;
  padding: 70px;
  border-radius: 10px;
  background: #FFFFFF;
  overflow-y: auto;
  max-height: 90vh;
  &-step {
    color: #6F6F6F;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 6px;
  }

  &-actions {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    & button {
      width: 34%;
      height: 50px;
      min-width: 180px;
    }
  }
}

.welcome-popup__card {
  &-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &-description {
    margin-top: 24px;
    font-weight: 350;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}

.welcome-popup-transition-enter-active {
  animation: welcome-popup-keyframes 0.1s ease-in-out;
}

.welcome-popup-transition-leave-active {
  animation: welcome-popup-keyframes 0.1s ease-in-out reverse;
}

@keyframes welcome-popup-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .welcome-popup__content {
    width: 70%;
    &-actions {
      flex-direction: column;
      align-items: center;
      & button:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .welcome-popup__content {
    width: 80%;
  }
}
</style>
