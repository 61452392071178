<template>
  <div class="container mb-6 text-center-mobile-only p-5">
    <h3 class="title is-3 has-text-weight-medium has-text-centered mb-5-5">Актуальные закупки</h3>
    <div class="shadow br-3 table-container overflow-x-auto">
      <b-table
        :data="tenderData"
        class="custom-table"
        :bordered="true"
        icon-pack="fas"
        :loading="tenderLoading"
        :mobile-cards="false"
       
      >
        <template v-for="column in columns">
          <b-table-column :key="column.id" v-bind="column" :cell-class="column.class">
            <template
              v-if="column.searchable && !column.numeric"
              #searchable="props"
            >
              <b-field>
                <b-input
                  v-model="props.filters[props.column.field]"
                  :placeholder="column.placeholder"
                  icon="search"
                />
              </b-field>
            </template>

            <template v-slot:header>
              <div 
              class="table-header-title" 
              :style="{ 'min-width': column.width + 'px' }">
                {{ column.label }}
              </div>
            </template>

            <template v-slot="props">
          
              <div
                v-if="column.use_dates_template"
                class="is-size-8 is-flex"
              >
             
                <span class="mr-2 mt-1">
                  <time-icon />
                </span>
                <span>
                  с
                  {{ props.row["date_from"] | moment("DD.MM.YYYY") }}
                  <br />
                  по {{ props.row["date_to"] | moment("DD.MM.YYYY") }}
                </span>
              </div>
              <div v-else-if="column.use_number_template" >
                {{ formatSpacesThousands(props.row[column.field]) }}
              </div>
              <div v-else-if="column.use_empty_template"></div>
              <div v-else-if="column.useSelectTemplate">
                
                {{ getSelectedTitle(column, props.row) }}   
              </div>
              <div v-else>
                <ShowMoreHover
                 :text="props.row[column.field]"
                 />
              </div>
            </template>
          </b-table-column>
        </template>
      </b-table>
      <div class="has-text-centered p-6">
        <router-link to="tenders" class="button is-normal main-button">
          <span class="icon is-small">
            <img
              src="../assets/svg/gavel.svg"
              alt="gavel"
              height="17"
              width="17"
            />
          </span>
          <span>ПРИНЯТЬ УЧАСТИЕ</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
const isSearchable = false;
import TimeIcon from "@/components/icons/TimeIcon.vue";
import ShowMoreHover from "@/components/common/ShowMoreHover";
import { filterSelects } from "@/home/TenderListFull/TenderListData";

export default {
  name: "TenderList",
  components: {
    TimeIcon,
    ShowMoreHover
  },
  data() {
    return {
      columns: [
          {
          field: "id",
          label: "№",
          placeholder: "Искать",
          searchable: isSearchable,
          centered: false,
          width: "15",
         
        },
        {
          field: "name",
          label: "НАИМЕНОВАНИЕ",
          placeholder: "Искать",
          searchable: isSearchable,
          centered: false,
          width: "150",
          class: 'td-name',
        },
        {
          field: "date",
          use_dates_template: true,
          label: "ДАТЫ ПРОВЕДЕНИЯ",
          placeholder: "Искать по дате",
          searchable: isSearchable,
          width: "120",
        },
        {
          field: "categories",
          label: "КАТЕГОРИИ",
          placeholder: "Искать по категории",
          searchable: isSearchable,
          useSelectTemplate: true,
          width: "75",
        },
        {
          field: "location",
          label: "ЛОКАЦИЯ",
          placeholder: "Искать по локации",
          use_empty_template: true,
          width: '50'
        },
        {
          field: "budget",
          use_number_template: true,
          label: "ПРИМЕРНЫЙ БЮДЖЕТ, РУБ",
          placeholder: "Искать по цене",
          searchable: isSearchable,
          width: '170'
        },
        {
          field: "description",
          label: "КРАТКОЕ ОПИСАНИЕ",
          placeholder: "Искать по описанию",
          searchable: isSearchable,
          width: '330'
        },
      ],
    };
  },
  computed: {
    tenderData() {
      return this.$store.getters["tender/list"];
    },
    tenderLoading() {
      return this.$store.getters["tender/loading"];
    },
  },
  methods: {
    getSelectedTitle(column, row) {
      const field = column.field;
      const selectedItemId = row[column.field];
      const selectedItem = filterSelects[field].find(item => item.id === selectedItemId);
      return selectedItem && selectedItem.title ? selectedItem.title : '';
    },
  },
  mounted() {
    this.$store.dispatch("tender/load", { showMore: false, demoPage: true });
 
  },
};
</script>

<style scoped>
.table-header-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #a01b5b;
}
.td-name{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.56px;
  color: #5A004B;
}
</style>