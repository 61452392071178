<template>
  <div class="partners container mb-2 text-center-mobile-only p-1 py-5">
    <h2 class="title is-4-5 mb-6 has-text-centered has-text-weight-medium">НАМ ДОВЕРЯЮТ</h2>
    <vue-slick-carousel v-if="partnersData.length" v-bind="sliderSettings" class="columns is-multiline m-0">
      <div class="column is-one-fifth" 
        v-for="item in partnersData"
        :key="item.id"
      >
        <div class="partners__item p-5">
          <img :src="item.img">
        </div>
      </div>
    </vue-slick-carousel>
    <div class="has-text-centered p-5" v-if="showLoadButton">
      <button 
        class="button is-normal border-button" 
        @click="loadMorePartners"
      >
        <span class="icon is-small">
          <img src="../assets/img/small-menu-icon.svg" alt="">
        </span>
        <span>Развернуть список</span>
      </button>
    </div>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'Partners',
  components: { VueSlickCarousel },
  data() {
    return {
      windowWidth: window.innerWidth,
      minSliderWidth: 768,
      sliderSettings: {
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 4096,
            settings: "unslick",
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              centerMode: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true
            }
          }
        ]
      },
    }
  },
  computed: {
    partnersData() {
      return (this.windowWidth <= this.minSliderWidth) ? 
        this.$store.getters["partners/list"] : 
        this.$store.getters["partners/list"].slice(0, this.currentPartnersCount);
    },
    currentPartnersCount() {
      return this.$store.getters["partners/currentPartnersCount"];
    },
    partnersPerPage() {
      return this.$store.getters["partners/partnersPerPage"];
    },
    showLoadButton() {
      return this.$store.getters['partners/showLoadButton'] && this.windowWidth > this.minSliderWidth
    }
  },
  methods: {
    loadMorePartners() {
      this.$store.dispatch('partners/load')
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    if (this.partnersData.length == 0)
      this.$store.dispatch('partners/load')
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
}
</script>
