<template>
  <section class="main-wrapper">
    <div class="main-content">
      <header-vue />
      <main-banner />
      <trading-direction />
      <tender-list />
      <free-information />
      <partners />
      <tender-participation />
      <faq />
    </div>
    <welcome-popup
        :is-shown="isOpenPopup"
    />
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import MainBanner from "@/home/MainBanner.vue";
import TradingDirection from "@/home/TradingDirection.vue";
import TenderList from "@/home/TenderList.vue";
import FreeInformation from "@/home/FreeInformation.vue";
import Partners from "@/home/Partners.vue";
import TenderParticipation from "@/home/TenderParticipation.vue";
import Faq from "@/home/FAQ.vue";
import WelcomePopup from '@/components/common/WelcomePopup';

export default {
  name: "Home",
  components: {
    WelcomePopup,
    HeaderVue,
    FooterVue,
    MainBanner,
    TenderParticipation,
    TradingDirection,
    TenderList,
    FreeInformation,
    Partners,
    Faq,
  },
  computed:{
    isOpenPopup() {
      return !!this.$route.query?.register
    }
  }
};
</script>
